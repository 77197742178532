<template>
  <auth-container>
    <c-heading
      class="title"
      as="h1"
      color="brand.900"
      fontSize="36px"
      fontWeight="600"
      marginBottom="80px"
    >
      Selamat Datang
    </c-heading>
    <c-box
      w="100%"
      textAlign="left"
      pb="100px"
      v-chakra="{
        '.icon-success': {
          display: 'none',
        },
        '.icon-error': {
          display: 'none',
        },
        '.text-err': {
          display: 'none',
        },
        '.has-invalid': {
          pos: 'relative',
          '.icon-error': {
            display: 'inline-block',
          },
          input: {
            border: '1px solid #EA4335',
          },
          '.text-err': {
            display: 'block',
          },
        },
        '.has-success': {
          pos: 'relative',
          '.icon-success': {
            display: 'inline-block',
          },
          input: {
            border: '1px solid #008C81',
          },
        },
      }"
    >
      <c-form-control
        :isInvalid="firstName == ''"
        :class="{
          'has-invalid': firstName == '',
          'has-success': firstName && firstName.length > 0,
        }"
        is-required
        marginBottom="30px"
        pos="relative"
      >
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeight="400"
          fontFamily="Roboto"
        >
          Nama Depan
        </c-form-label>
        <c-text
          class="text-err"
          fontSize="14px"
          fontFamily="Roboto"
          color="#EA4335"
          pos="absolute"
          top="0"
          right="0"
        >
          Nama depan tidak sesuai
        </c-text>
        <c-input-group size="md">
          <c-input
            type="text"
            error-border-color="red.300"
            id="FirstName"
            placeholder="Masukkan nama depan"
            height="62px"
            v-model="firstName"
          />
          <c-input-right-element width="4.5rem" mt="18px" class="icon-success">
            <c-icon name="check-circle" size="18px" color="green.500" />
          </c-input-right-element>
          <c-input-right-element width="4.5rem" mt="18px" class="icon-error">
            <c-icon name="exclamation" size="18px" color="red.500" />
          </c-input-right-element>
        </c-input-group>
      </c-form-control>
      <c-form-control
        :isInvalid="lastName == ''"
        :class="{
          'has-invalid': lastName == '',
          'has-success': lastName && lastName.length > 0,
        }"
        marginBottom="30px"
      >
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeight="400"
          fontFamily="Roboto"
        >
          Nama Belakang
        </c-form-label>
        <c-text
          class="text-err"
          fontSize="14px"
          fontFamily="Roboto"
          color="#EA4335"
          pos="absolute"
          top="0"
          right="0"
        >
          Nama belakang tidak sesuai
        </c-text>
        <c-input-group size="md">
          <c-input
            id="lastName"
            placeholder="Masukkan nama belakang"
            height="62px"
            type="text"
            v-model="lastName"
          />
          <c-input-right-element width="4.5rem" mt="18px" class="icon-success">
            <c-icon name="check-circle" size="18px" color="green.500" />
          </c-input-right-element>
          <c-input-right-element width="4.5rem" mt="18px" class="icon-error">
            <c-icon name="exclamation" size="18px" color="red.500" />
          </c-input-right-element>
        </c-input-group>
      </c-form-control>
      <c-form-control
        :isInvalid="birthDate == ''"
        is-required
        marginBottom="30px"
      >
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeight="400"
          fontFamily="Roboto"
        >
          Tanggal Lahir
        </c-form-label>
        <c-input-group size="md">
          <c-input
            id="birthDate"
            error-border-color="red.300"
            placeholder="Masukkan tanggal lahir"
            height="62px"
            type="date"
            v-model="birthDate"
          />
        </c-input-group>
      </c-form-control>
      <c-form-control marginBottom="30px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeight="400"
          fontFamily="Roboto"
        >
          Jenis Kelamin
        </c-form-label>
        <c-input-group size="md">
          <c-select placeholder="Jenis Kelamin" v-model="gender" height="62px">
            <option value="female">Perempuan</option>
            <option value="male">Laki-Laki</option>
          </c-select>
        </c-input-group>
      </c-form-control>
      <c-form-control :isInvalid="!address" is-required marginBottom="30px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeight="400"
          fontFamily="Roboto"
        >
          Alamat
        </c-form-label>
        <!-- <c-input-group size="md"> -->
        <textarea
          rows="3"
          v-chakra
          id="address"
          error-border-color="red.300"
          placeholder="Masukkan alamat"
          d="block"
          border="1px solid #E2E8F0"
          border-radius="md"
          minHeight="62px"
          type="text"
          lineHeight="20px"
          p="10px"
          w="100%"
          :style="address == '' ? 'border-color: #EA4335;' : ''"
          v-model="address"
        />
        <!-- </c-input-group> -->
      </c-form-control>
      <c-form-control is-required marginBottom="30px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeight="400"
          fontFamily="Roboto"
        >
          Negara
        </c-form-label>
        <c-select placeholder="Pilih Negara" v-model="country" height="62px">
          <option
            v-for="item in countries"
            :key="item.name"
            :value="item.name"
          >
            {{ item.label }}
          </option>
        </c-select>
      </c-form-control>
      <c-form-control is-required marginBottom="30px">
        <c-form-label
          fontSize="14"
          color="#555"
          fontWeight="400"
          fontFamily="Roboto"
        >
          Provinsi
        </c-form-label>
        <c-input-group size="md">
          <c-select
            placeholder="Pilih Provinsi"
            v-model="province"
            height="62px"
          >
            <option
              v-for="item in states"
              :key="item.name"
              :value="item.name"
            >
              {{ item.name }}
            </option>
          </c-select>
        </c-input-group>
      </c-form-control>
      <c-form-control is-required marginBottom="30px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeight="400"
          fontFamily="Roboto"
        >
          Kota
        </c-form-label>
        <c-select placeholder="Pilih Kota" v-model="city" height="62px">
          <option v-for="item in cities" :key="item.name" :value="item.name">
            {{ item.name }}
          </option>
        </c-select>
      </c-form-control>
      <c-form-control
        :isInvalid="zipCode == ''"
        is-required
        marginBottom="30px"
      >
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeight="400"
          fontFamily="Roboto"
        >
          Kode Pos
        </c-form-label>
        <c-input-group size="md">
          <c-input
            id="posCode"
            error-border-color="red.300"
            placeholder="Masukkan Kode Post"
            height="62px"
            type="text"
            v-model="zipCode"
          />
        </c-input-group>
      </c-form-control>
      <c-form-control
        @keyup="onlyNumber"
        :isInvalid="phone == ''"
        is-required
        marginBottom="30px"
      >
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeight="400"
          fontFamily="Roboto"
        >
          Nomor Telepon
        </c-form-label>
        <c-input-group size="md">
          <c-input
            id="phone"
            placeholder="Masukkan nomor telepon"
            height="62px"
            type="tel"
            pattern="[0-9]{15}"
            maxlength="15"
            v-model="phone"
          />
        </c-input-group>
      </c-form-control>
      <c-form-control :isInvalid="email == ''" is-required marginBottom="30px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeight="400"
          fontFamily="Roboto"
        >
          Email
        </c-form-label>
        <c-input-group size="md">
          <c-input
            id="email"
            placeholder="Masukkan email"
            height="62px"
            type="email"
            v-model="email"
          />
        </c-input-group>
      </c-form-control>
      <c-form-control
        :isInvalid="$v.password.$invalid"
        is-required
        marginBottom="30px"
      >
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeight="400"
          fontFamily="Roboto"
        >
          Password
        </c-form-label>
        <c-input-group size="md">
          <c-input
            id="password"
            placeholder="Masukkan password"
            height="62px"
            type="password"
            v-model="password"
          />
        </c-input-group>
        <c-form-helper-text v-if="$v.password.$invalid" color="red">
          <ul v-chakra ml="20px">
            <li v-if="!$v.password.minLength">minimum 8 characters</li>
            <li v-if="!$v.password.alphaNum">alphanumeric</li>
            <li v-if="!$v.password.upperCase">uppercase letter</li>
            <li v-if="!$v.password.lowerCase">lowercase letters</li>
            <li v-if="!$v.password.hasSpecialCharacter">must include unique @$!%()*?&</li>
          </ul>
        </c-form-helper-text>
      </c-form-control>
      <c-form-control marginBottom="30px">
        <c-button
          backgroundColor="brand.900"
          px="26px"
          fontSize="18px"
          fontWeight="500"
          py="26px"
          borderRadius="58px"
          width="100%"
          color="white"
          :isDisabled="isDisabledButton"
          @click="register()"
        >
          Register
        </c-button>
        <template v-if="errors.length > 0">
          <c-form-helper-text v-for="error in errors" :key="error" color="red">
            {{ error }}
          </c-form-helper-text>
        </template>
      </c-form-control>
      <c-text
        mt="30px"
        color="#888888"
        fontSize="16px"
        textAlign="center"
        fontFamily="Roboto"
      >
        Sudah punya akun?
        <c-link
          color="brand.900"
          fontWeight="500"
          textDecoration="underline"
          as="router-link"
          :to="{ name: 'auth.login' }"
        >
          Masuk
        </c-link>
      </c-text>
    </c-box>
  </auth-container>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import AuthContainer from "@/components/auth-container";
import {minLength, required, alphaNum} from "vuelidate/lib/validators";

export default {
  name: "RegisterPage",
  components: {
    AuthContainer,
  },
  data() {
    return {
      firstName: null,
      lastName: null,
      birthDate: null,
      address: null,
      gender: null,
      country: null,
      province: null,
      city: null,
      zipCode: null,
      phone: null,
      email: null,
      password: null,
      // ---
      errors: [],
      countries: [],
      states: [],
      cities: [],
    };
  },
  computed: {
    ...mapGetters(["axios"]),
    isDisabledButton() {
      return (
        !this.firstName ||
        !this.birthDate ||
        !this.address ||
        _.isEmpty(this.country) ||
        _.isEmpty(this.province) ||
        _.isEmpty(this.city) ||
        !this.zipCode ||
        !this.phone ||
        !this.email ||
        !this.password
      );
    },
    countryId() {
      return this.countries.find(it => it.name === this.country)?.id;
    },
    provinceId() {
      return this.states.find(it => it.name === this.province)?.id;
    },
  },
  methods: {
    ...mapActions({
      signup: "auth/signup",
    }),
    register() {
      let data = _.omit(
        {
          ...this._data,
          country: this.country,
          province: this.province,
          city: this.city,
          gender: this.gender,
        },
        "errors",
        "countries",
        "states",
        "cities",
        "success"
      );
      this.errors = [];

      this.signup(data)
        .then(() => {
          this.$router.push({ name: "auth.success" });
        })
        .catch((err) => {
          this.errors = [...err];
        });
    },
    async getCities(stateId) {
      let { data } = await this.axios
        .get(`/v1/general/cities?stateId=${stateId}`)
        .then((it) => it.data);
      this.cities = data.map((data) => {
        return {
          ...data,
          value: data.name,
          label: data.name,
        };
      });
    },
    async getStates(countryId) {
      let { data } = await this.axios
        .get(`/v1/general/states?countryId=${countryId}`)
        .then((it) => it.data);
      this.states = data.map((data) => {
        return {
          ...data,
          value: data.name,
          label: data.name,
        };
      });
    },
    async getCountries() {
      let { data } = await this.axios
        .get("/v1/general/countries")
        .then((it) => it.data);
      this.countries = data.map((data) => {
        return {
          ...data,
          value: data.name,
          label: data.name,
        };
      });
    },
    onlyNumber(event) {
      if (!event.target.value.replace(/\D/g, "").match(/(\d{0,15})/).input) {
        event.target.value = "";
        this.phone = "";
      }
    },
  },
  watch: {
    countryId: {
      immediate: true,
      handler(id) {
        if (id == null) return;
        this.getStates(id);
      },
    },
    provinceId: {
      immediate: true,
      handler(id) {
        if (id == null) return;
        this.getCities(id);
      },
    },
  },
  async mounted() {
    await this.getCountries();
  },
  validations: {
    password: {
      required,
      minLength: function (value) {
        return value?.length >= 8;
      },
      alphaNum: function (value) {
        return /[a-zA-Z0-9]+/.test(value) && value?.length > 0;
      },
      hasSpecialCharacter: function (value) {
        return /[@$!%*?&()#]/.test(value) && value?.length > 0;
      },
      upperCase: function (value) {
        return /[A-Z]/.test(value) && value?.length > 0;
      },
      lowerCase: function (value) {
        return /[a-z]/.test(value) && value?.length > 0;
      },
    },
  },
};
</script>

<style></style>
